import { useRef, useState } from 'react';
import './App.css';
import PanAndZoomMap from './Components/PanAndZoomMap';
import NavigationButton from './Components/NavigationButton';
import Screen from './Components/Screen';
import Splash from './Components/Splash';

function App() {
  const splashRef = useRef();
  const homeRef = useRef();
  const overviewRef = useRef();
  const mapsRef = useRef();
  const buyRef = useRef();
  const authorRef = useRef();

  const [mapIsRendering, setMapIsRendering] = useState(false);

  let unloadedResources = 2;
  const markResourceLoaded = () => {
    unloadedResources -= 1;
    if (unloadedResources <= 0) {
      document.getElementById("openingSpinner").style.display = "none";
      splashRef.current.show();
    }
  };

  const HOME_TO_OVERVIEW_ANGLE = Math.PI * 1.1;
  const HOME_TO_BUY_ANGLE = Math.PI * -0.1;
  const HOME_TO_MAPS_ANGLE = Math.PI * 0.8;
  const HOME_TO_AUTHOR_ANGLE = Math.PI * 0.2;

  return (
    <>
      <div className="App">
        {/* Splash screen */}
        <Screen ref={splashRef}>
          <Splash />
          <NavigationButton onClick={() => {
            const angle = Math.PI / 2;
            homeRef.current.animateIn(angle);
            splashRef.current.animateOut(angle + Math.PI);
          }} 
          options="startButtonPlacement fadeIn" 
          text="Enter Site" />
          <br />
        </Screen>

        {/* Test screen 1: should be replaced with home screen */}
        <Screen ref={homeRef}>
          <img className="blurb" src={process.env.PUBLIC_URL + "/blurb_4096.png"} />
          <img className="logo" src={process.env.PUBLIC_URL + "/logo192.png"} />

          <p className="stinger">
            <i>Jazei</i>, a gripping new fantasy novel from author Justin Murray, follows Kenlin, Ranja, and 
            Klanjad — the last of the legendary, heroic Jazei lineage. But the overwhelming might and terrifying 
            magics of a barbaric invasion will force them to ask… Is heroism enough? To fight a monstrous foe, 
            what must they be prepared to do? And what are they willing to <i>be?</i>
          </p>

          {/* <p className="info">
            Enter the minds of those who carve their wills into reality. Forget what the legends told you; 
            legends are hazy, watered-down fancies softened and sifted through countless retellings. But behind 
            the legends are people — real people, as the Wanderer remembers. They think cunningly. They fight 
            viciously. And when champions fail and heroism isn't enough anymore, they become what anyone 
            sane would fear to be.
          </p>
          <p className="info">
            These people are Jazei. This is their story.
          </p> */}

          <NavigationButton onClick={() => {
            const angle = HOME_TO_OVERVIEW_ANGLE;
            overviewRef.current.animateIn(angle);
            homeRef.current.animateOut(angle + Math.PI);
          }} 
          options="storyOverview"
          text="Story Overview" />
          
          <NavigationButton onClick={() => {
            const angle = HOME_TO_BUY_ANGLE;
            buyRef.current.animateIn(angle);
            homeRef.current.animateOut(angle + Math.PI);
          }} 
          options="buy"
          text="Where to Buy" />

          <br/>

          <NavigationButton onClick={() => {
            setMapIsRendering(true);
            const angle = HOME_TO_MAPS_ANGLE;
            mapsRef.current.animateIn(angle);
            homeRef.current.animateOut(angle + Math.PI);
          }} 
          options="maps"
          text="Maps & Info" />
          
          <NavigationButton onClick={() => {
            const angle = HOME_TO_AUTHOR_ANGLE;
            authorRef.current.animateIn(angle);
            homeRef.current.animateOut(angle + Math.PI);
          }} 
          options="author"
          text="About the Author" />

          <br/>

          {/* <NavigationButton onClick={() => {
            const angle = Math.PI / 2;
            splashRef.current.animateIn(angle);
            homeRef.current.animateOut(angle + Math.PI);
          }} 
          options="startButtonPlacement"
          text="Splash" /> */}
        </Screen>

        {/* Story overview screen */}
        <Screen ref={overviewRef}>
          <div className="blurbDiv">
            <p className="blurb">
              <i>I am Jazei. What does that mean?</i>
            </p>
            <p className="blurb">
              Warriors, magicians, champions, heroes… For more than an age, legends of the Jazei bloodline have captivated 
              storytellers and listeners in every corner of Jranjana. Kenlin grew up hearing these legends, and he loved to 
              hear them. But they were never real to him…until the night he shouts a warning to two strangers, learns of his 
              own Jazei heritage, and is violently catapulted into a war of desperation.
            </p>
            <p className="blurb">
              These are dying days. Invaders from across the sea have conquered the west, crushing the people beneath a regime 
              of tyranny, ceaseless war, and cultic human sacrifice. What few kingdoms remain unconquered are exhausted, 
              divided, weak…dying. Even the Jazei lineage is dying: Kenlin, his uncle Klanjad, and his cousin Ranja are the last. 
              Wherever they travel, they are hunted by mercenaries and assassins, and their fights for survival leave an 
              ever-growing trail of destruction and death.
            </p>
            <p className="blurb">
              And <i>these</i> are Jranjana's champions? Is this heroism? The legends speak of honor and noble prowess, but 
              no one truly fights for their lives and walks away clean. Perhaps the legends were never real at all. Perhaps 
              heroes and champions are lies. In a losing war on the brink of impending, inevitable defeat, perhaps <i>Jazei</i> is 
              really a choice — not to learn who you are, but to decide what you are willing to be.
            </p>
            <p className="blurb">
              For legends spring equally from champions and monsters. When humanity dies in fire and all its champions fall, 
              what will rise to take their place? To win the world when heroes fail, who would be willing to become something 
              monstrous?
            </p>
          </div>
          <NavigationButton onClick={() => {
            const angle = HOME_TO_OVERVIEW_ANGLE;
            homeRef.current.animateIn(angle + Math.PI);
            overviewRef.current.animateOut(angle);
          }} 
          options="newline"
          text="Back" />
        </Screen>
        
        {/* Maps screen */}
        <Screen ref={mapsRef}>
          <PanAndZoomMap rendering={mapIsRendering} />
          <NavigationButton onClick={() => {
            setMapIsRendering(false);
            const angle = HOME_TO_MAPS_ANGLE;
            homeRef.current.animateIn(angle + Math.PI);
            mapsRef.current.animateOut(angle);
          }} 
          options="newline"
          text="Back" />
        </Screen>
        
        {/* Buy screen */}
        <Screen ref={buyRef}>
        <p className="blurb">Coming soon!</p>
          <NavigationButton onClick={() => {
            const angle = HOME_TO_BUY_ANGLE;
            homeRef.current.animateIn(angle + Math.PI);
            buyRef.current.animateOut(angle);
          }} 
          options="startButtonPlacement"
          text="Back" />
        </Screen>
        
        {/* Author screen */}
        <Screen ref={authorRef}>
          <img className="author" src={process.env.PUBLIC_URL + "/author.jpg"} alt="The author, Justin Murray" />
          <p className="author">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure 
            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat 
            non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <NavigationButton onClick={() => {
            const angle = HOME_TO_AUTHOR_ANGLE;
            homeRef.current.animateIn(angle + Math.PI);
            authorRef.current.animateOut(angle);
          }} 
          options="startButtonPlacement"
          text="Back" />
        </Screen>

        {/* Resource loaders */}
        <img className="loader" src={process.env.PUBLIC_URL + "/background_2048.jpg"} onLoad={markResourceLoaded} />
        <img className="loader" src={process.env.PUBLIC_URL + "/splash_2048.jpg"} onLoad={markResourceLoaded} />
      </div>
    </>
  );
}

export default App;
