import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import "./Screen.css";

function delay(millis) {
    return new Promise((resolve) => {
        setTimeout(resolve, millis);
    });
}

const Screen = forwardRef(function Screen(props, ref) {
    const inputElement = useRef();

    const handleResized = () => {
        const div = inputElement.current;
        if (window.innerHeight / window.innerWidth >= 2) {
            div.style.height = "";
            div.style.width = "100%";
        } else {
            div.style.height = "100%";
            div.style.width = "";
        }
    };

    const ANIMATION_TIME_MS = 800;
    const ANIMATION_RADIUS = 200;

    const internalAnimateIn = (angle) => {
        return new Promise((resolve) => {
            const div = inputElement.current;
            div.style.display = "block";
            const unit = window.innerWidth > window.innerHeight ? "vw" : "vh";
            const keyframes = [
                { transform: `translate3D(calc(${ANIMATION_RADIUS * Math.cos(angle)}${unit} - 50%), calc(${ANIMATION_RADIUS * Math.sin(angle)}${unit} - 50%), 0)`},
                { transform: "translate3D(-50%, -50%, 0)" },
            ];
            const timing = {
                duration: ANIMATION_TIME_MS,
                iterations: 1,
                easing: "ease-out",
            };
    
            var animation = div.animate(keyframes, timing);
            animation.addEventListener("finish", () => {
                resolve();
            });
        });
    };
    
    const internalAnimateOut = (angle) => {
        return new Promise((resolve) => {
            const div = inputElement.current;
            div.style.display = "block";
            const unit = window.innerWidth > window.innerHeight ? "vw" : "vh";
            const keyframes = [
                { transform: "translate3D(-50%, -50%, 0)" },
                { transform: `translate3D(calc(${ANIMATION_RADIUS * Math.cos(angle)}${unit} - 50%), calc(${ANIMATION_RADIUS * Math.sin(angle)}${unit} - 50%), 0)`},
            ];
            const timing = {
                duration: ANIMATION_TIME_MS,
                iterations: 1,
                easing: "ease-out",
            };
    
            var animation = div.animate(keyframes, timing);
            animation.addEventListener("finish", () => {
                div.style.display = "none";
                resolve();
            });
        });
    };

    const internalShow = () => {
        const div = inputElement.current;
        div.style.display = "block";
    };

    const internalHide = () => {
        const div = inputElement.current;
        div.style.display = "none";
    }

    useImperativeHandle(ref, () => {
        return {
            animateIn(angle) {
                internalAnimateIn(angle);
            },
            animateOut(angle) {
                internalAnimateOut(angle);
            },
            show() {
                internalShow();
            },
            hide() {
                internalHide();
            }
        };
    });

    useEffect(() => {
        handleResized()

        window.addEventListener("resize", handleResized);
        return () => {
            window.removeEventListener("resize", handleResized);
        }
    }, []);

    return <>
        <div className="screen" ref={inputElement}>
            <img className="screenBackground" src={process.env.PUBLIC_URL + "/background_2048.jpg"} />
            <div className="screenContent">
                {props.children}
            </div>
        </div>
    </>;
});

export default Screen;