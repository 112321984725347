import { useEffect, useRef, useState } from 'react';
import './PanAndZoomMap.css'
import { Camera, Color4, Engine, FreeCamera, MeshBuilder, Observable, PBRMaterial, PointerEventTypes, Scene, Texture, TmpVectors, TransformNode, Vector3 } from '@babylonjs/core';

const clamp = (value, min, max) => {
    return Math.min(max, Math.max(value, min));
};

const createMapExperience = (canvas, whenFinishedLoadingCallback) => {
    const whenDisposedObservable = new Observable();

    // Engine
    const engine = new Engine(canvas);
    const handleResize = () => {
        engine.resize();
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    whenDisposedObservable.add(() => {
        window.removeEventListener("resize", handleResize);
        engine.dispose();
    });
    // Enhance render clarity without disabling mips (which causes graininess) by manually oversampling.
    engine.setHardwareScalingLevel(0.5);

    // Scene
    const scene = new Scene(engine);
    scene.clearColor = new Color4(0, 0, 0, 0);
    engine.runRenderLoop(() => {
        scene.render();
    });

    // Camera
    const camera = new FreeCamera("camera", Vector3.Zero(), scene);
    camera.mode = Camera.ORTHOGRAPHIC_CAMERA;
    const aspectRatio = canvas.width / canvas.height;
    camera.orthoBottom = -0.5 / aspectRatio;
    camera.orthoTop = 0.5 / aspectRatio;
    camera.orthoLeft = -0.5;
    camera.orthoRight = 0.5;

    // Geometry
    const mapParent = new TransformNode("mapParent", scene, true);
    mapParent.setPositionWithLocalVector(Vector3.Forward());

    const mapMesh = MeshBuilder.CreatePlane("mapMesh");
    mapMesh.isPickable = false;
    mapMesh.isVisible = false;
    mapMesh.setParent(mapParent);
    mapMesh.setPositionWithLocalVector(Vector3.Zero());

    const pickMesh = MeshBuilder.CreatePlane("pickMesh", { width: 10, height: 10 });
    pickMesh.isPickable = true;
    pickMesh.setPositionWithLocalVector(Vector3.Forward());
    pickMesh.material = new PBRMaterial("clearMat", scene);
    pickMesh.material.alpha = 0;

    // Panning and zooming.
    let priorInteractionScale = 0;
    let currentInteractionScale = 0;
    let currentMapScale = 1;
    let priorInteractionPoint = null;
    const panningInertia = new Vector3();
    const currentInteractionPoint = Vector3.Zero();
    const pan = () => {
        mapParent.position.addInPlace(panningInertia);
    }
    const updateMapParent = (point) => {
        const delta = TmpVectors.Vector3[0];
        delta.copyFrom(point);
        delta.subtractInPlace(mapParent.position);
        delta.scaleInPlace(1 / mapParent.scaling.x);
        mapMesh.position.subtractInPlace(delta);
        mapParent.position.copyFrom(point);
    };
    const constrainPanning = () => {
        updateMapParent(Vector3.ZeroReadOnly);
        const bounds = TmpVectors.Vector2[0];
        bounds.set(mapMesh.scaling.x, mapMesh.scaling.y);
        bounds.scaleInPlace(0.5);
        mapMesh.position.set(
            clamp(mapMesh.position.x, -bounds.x, bounds.x),
            clamp(mapMesh.position.y, -bounds.y, bounds.y),
            mapMesh.position.z);
    };
    const updateMapScale = () => {
        currentMapScale = clamp(currentMapScale, 1, 25);
        mapParent.scaling.copyFrom(Vector3.OneReadOnly);
        mapParent.scaling.scaleInPlace(currentMapScale);
    }
    const pointersToPoints = new Map();
    scene.onPointerObservable.add((pointerInfo) => {
        switch (pointerInfo.type) {
            case PointerEventTypes.POINTERWHEEL: {
                // Wheel zoom handling, should be done here rather than in update loop because wheel is on mouse 
                // which does not produce an interaction point.
                const pickingInfo = scene.pick(pointerInfo.event.offsetX, pointerInfo.event.offsetY);
                if (pickingInfo !== null) {
                    updateMapParent(pickingInfo.pickedPoint);
                    currentMapScale *= (1 - 0.002 * pointerInfo.event.deltaY);
                    updateMapScale();
                }
                break;
            }
            case PointerEventTypes.POINTERDOWN: {
                const pickingInfo = scene.pick(pointerInfo.event.offsetX, pointerInfo.event.offsetY);
                if (pickingInfo !== null) {
                    pointersToPoints.set(pointerInfo.event.pointerId, pickingInfo.pickedPoint);
                }
                priorInteractionPoint = null;
                break;
            }
            case PointerEventTypes.POINTERMOVE: {
                if (pointersToPoints.has(pointerInfo.event.pointerId)) {
                    const pickingInfo = scene.pick(pointerInfo.event.offsetX, pointerInfo.event.offsetY);
                    pointersToPoints.set(pointerInfo.event.pointerId, pickingInfo.pickedPoint);
                }
                break;
            }
            case PointerEventTypes.POINTERUP: {
                pointersToPoints.delete(pointerInfo.event.pointerId);
                priorInteractionPoint = null;
                break;
            }
            default:
                break;
        }
    });
    const calculateInteractionPoint = (interactionPoint) => {
        interactionPoint.set(0, 0, 0);
        pointersToPoints.forEach((point) => {
            interactionPoint.addInPlace(point);
        });
        interactionPoint.scaleInPlace(1 / pointersToPoints.size);
    };
    const updateInteractionScale = () => {
        priorInteractionScale = currentInteractionScale;
        currentInteractionScale = 0;
        pointersToPoints.forEach((u) => {
            pointersToPoints.forEach((v) => {
                currentInteractionScale += Vector3.Distance(u, v);
            });
        });
        currentInteractionScale /= (((pointersToPoints.size * (pointersToPoints.size - 1))) / 2)
    }
    scene.onBeforeRenderObservable.add(() => {
        if (priorInteractionPoint === null && pointersToPoints.size > 0) {
            // Start a new interaction
            priorInteractionPoint = Vector3.Zero();
            calculateInteractionPoint(priorInteractionPoint);
            if (pointersToPoints.size > 0) {
                updateInteractionScale();
            }
        } else if (pointersToPoints.size > 0) {
            // Pan
            calculateInteractionPoint(currentInteractionPoint);
            panningInertia.copyFrom(currentInteractionPoint);
            panningInertia.subtractInPlace(priorInteractionPoint);
            pan();

            // Pinch zoom
            if (pointersToPoints.size > 1) {
                updateMapParent(currentInteractionPoint);
                updateInteractionScale();
                currentMapScale *= (currentInteractionScale / priorInteractionScale);
                updateMapScale();
            }

            constrainPanning();
                
            priorInteractionPoint.copyFrom(currentInteractionPoint);
        } else if (pointersToPoints.size === 0) {
            priorInteractionPoint = null;
            panningInertia.scaleInPlace(0.92);
            pan();
            constrainPanning();
        }
    });

    // Material
    const mapMaterial = new PBRMaterial("mat", scene);
    mapMaterial.unlit = true;
    mapMesh.material = mapMaterial;

    // TODO: Add logo as spinner before low-res texture loads.
    
    // Texture loading
    const lowResMapTexture = new Texture(process.env.PUBLIC_URL + "/map_1k.jpg", scene, undefined, undefined, undefined, () => {
        mapMaterial.albedoTexture = lowResMapTexture;
        const textureSize = lowResMapTexture.getSize();
        mapMesh.scaling = new Vector3(1,  textureSize.height / textureSize.width, 1);
        mapMesh.isVisible = true;
        
        const highResMapTexture = new Texture(process.env.PUBLIC_URL + "/map_8k.jpg", scene, undefined, undefined, undefined, () => {
            mapMaterial.albedoTexture = highResMapTexture;
            lowResMapTexture.dispose();
            whenFinishedLoadingCallback();
        });
    });

    return () => {
        whenDisposedObservable.notifyObservers();
    }
};

const PanAndZoomMap = ({rendering}) => {
    const canvasRef = useRef();
    const [loadingTextClass, setLoadingTextClass] = useState("mapLoadingText");

    useEffect(() => {
        if (rendering) {
            const cleanupFunction = createMapExperience(canvasRef.current, () => { setLoadingTextClass("mapLoadingTextHidden"); });
            return cleanupFunction;
        }
    }, [rendering]);

    return <>
        <div className="mapContainer">
            <canvas className="mapCanvas" ref={canvasRef} />
            <p className={loadingTextClass}>Loading detail...</p>
        </div>
    </>;
};

export default PanAndZoomMap;
