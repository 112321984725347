import React from 'react';
import './NavigationButton.css';

function NavigationButton(props) {
    return <>
        <button className={"basic " + props.options} onClick={props.onClick}>
            {props.text}
        </button>
    </>;
}

export default NavigationButton;