import React from "react";
import "./Splash.css";

function Splash() {
    return <>
        <img src={process.env.PUBLIC_URL + "/splash_2048.jpg"} className="splash" />
    </>;
};

export default Splash;
